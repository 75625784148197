/* src/pages/search.js */
import React, { useState, useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import { Index } from "lunr"
import { getColor,getTransmissionType,getFuelType } from '../Modules/filter-data-tools'
import Layout from "../components/layout"
import '../scss/srp-side-style.scss'
import '../scss/srp-fonts.scss'
import '../scss/srp-normalize.scss'

import { makeStyles } from '@material-ui/core/styles';
import { captureEvent } from 'event-service/modules/capture'
import Slider from '@material-ui/core/Slider';
import SearchRecommended from "./SearchRecommended"
import { ModalVehicleProvider } from "../components/modal/storage/VehicleContext"
import InnerHTML from 'dangerously-set-html-content'

// We can access the results of the page GraphQL query via the data props
const SearchPage = ({ data, location, pageContext }) => {
  const useStyles = makeStyles({
    root: {
      width: 300,
    },
  });
  function numberWithCommas(x) {
    if(x != undefined){
      return  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
      return "0"
    }
  }

  const localStorage = typeof window !== 'undefined' && window.localStorage
  const document = typeof window !== 'undefined' && window.document
  const history = typeof window !== 'undefined' && window.history
  const siteSettings = JSON.parse(data.siteData.siteData.siteSettings)
  
  const pageContentSEO = pageContext?.pageContent?.SEO?.seo;
  const pageContentSRP = pageContext?.pageContent?.SRP?.querySearchParams;
  const additionalInventory = pageContext?.altInventory !== undefined ? pageContext?.altInventory : false
  const additionalInventorySlug = pageContext?.addtlInvenSlug !== undefined ? pageContext?.addtlInvenSlug : ''
  const soldSort = true;

  const usePriority = pageContext?.pageContent?.SRP?.usePriority !== undefined ? pageContext?.pageContent?.SRP?.usePriority : false
  const priorityMake = pageContext?.pageContent?.SRP?.priorityMake !== undefined ? pageContext?.pageContent?.SRP?.priorityMake.split(',') : []
  
  const adjustedParam = location.search;
  const adjustedParamCheck = adjustedParam.includes('fq');
  const [queryState, setQueryState] = useState(pageContentSRP && !adjustedParamCheck ? pageContentSRP:location.search );
  const SearchParam = pageContentSRP && !adjustedParamCheck ? pageContentSRP:location.search;

  useEffect(()=>{
    if(pageContentSRP !== (null || undefined ) && !adjustedParamCheck){
      const url = window.location.href
      history.replaceState({ path: url }, null, `?${pageContentSRP}`);
      console.log(window.location.search);
      const tryparams = pageContentSRP.split('&').reduce((acc, s) => {
        const [k, v] = s.split('=')
        return Object.assign(acc, {'key':k, 'val':v})
      }, {})
    }else{

    }

  },[SearchParam])
  let pageSeo = {seo: pageContentSEO? pageContentSEO : siteSettings.seo.search}

  let theSlug = additionalInventory ? ".." + additionalInventorySlug + (additionalInventorySlug.endsWith('/') ? '' : '/') + siteSettings.inventorymanagement.vdpslug : siteSettings.inventorymanagement.vdpslug
  let SRPCardBtns = siteSettings.inventorymanagement.vehiclecardbutton
  let CostRange = parseInt(siteSettings.inventorymanagement.costrange)
  let MileageRange = parseInt(siteSettings.inventorymanagement.mileagerange)
  let AutoCheckEnabled = siteSettings.inventorymanagement.AutoCheckEnabled
  let HideNewUsed = siteSettings.inventorymanagement.HideNewUsed

  const lead_url = data.site.siteMetadata.lead_url
  const site_id = data.site.siteMetadata.site_id
  const gtmId = siteSettings.dealerInfo.gtmId
  
  const unavailableImg = siteSettings.inventorymanagement.unavailableImg
  const disclaimer = siteSettings.inventorymanagement.disclaimerSRP
  const disclaimerCalculator = siteSettings.inventorymanagement.disclaimerCalculator
  const disclaimerContact = siteSettings.inventorymanagement.disclaimerContact
  const disclaimerFinanceApp = siteSettings.inventorymanagement.disclaimerFinanceApp
  const downpayment = Number(siteSettings.inventorymanagement.downpayment)
  const rates = [
    Number(siteSettings.inventorymanagement.aRate),
    Number(siteSettings.inventorymanagement.bRate),
    Number(siteSettings.inventorymanagement.cRate),
    Number(siteSettings.inventorymanagement.dRate),
    Number(siteSettings.inventorymanagement.eRate),
    Number(siteSettings.inventorymanagement.fRate),
  ]
  const defaultMonth = Number(siteSettings.inventorymanagement.months)
  const fallbackText = siteSettings.inventorymanagement.fallbackText
  const showSpecs = siteSettings.inventorymanagement.showSpecs
  const showTrim = siteSettings.inventorymanagement.showTrim
  const netPriceCustomText = siteSettings.inventorymanagement.netPriceCustomText

  const topHtmlSRP = siteSettings.inventorymanagement.topHtmlSRP
  const botHtmlSRP = siteSettings.inventorymanagement.botHtmlSRP

  const staticTopHtmlSrp =  pageContext?.pageContent?.SRP?.topHtmlStaticSRP;
  const staticBottomHtmlSRP = pageContext?.pageContent?.SRP?.botHtmlStaticSRP;

  const sortKey = useRef(null);
  const sortValue = useRef(null);
  const sortTitle = useRef(null);
  const secondarySortKey = useRef(null);
  const secondarySortValue = useRef(null);
  const secondarySortTitle = useRef(null);

  const overwriteCTAs = pageContext?.pageContent?.SRP?.overwriteCTAs;
  const staticBtns = pageContext?.pageContent?.SRP?.SRPSettings;
  const overwriteInlineOffers = pageContext?.pageContent?.InlineOffers?.overwriteInlineOffers;
  const staticSrpInlineOffers = pageContext?.pageContent?.InlineOffers;

  useEffect(()=>{    
    insertUrlParamSorting(sortKey.current, sortValue.current, sortTitle.current);

},[])

  function formatPrice(price, defaultPrice) {
    var decSpot = siteSettings.inventorymanagement.decimalSpaceHide ? 0 : 2
    let thePrice = (defaultPrice != undefined && price == 0
        ? defaultPrice
        : '$' + price.toFixed(decSpot).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    return thePrice
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }
  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }
  function removeStorageItem(item){
    typeof localStorage.removeItem !== 'undefined'
      ? localStorage.removeItem(item)
      : console.log('no localStorage')
  }

  // LunrIndex is available via page query
  const { store } = data.AltLunrIndex
  let MaxCost = store.price[store.price.length - 1]

  if(MaxCost === 0 || MaxCost === undefined ){
      MaxCost = 5000// CostRange
    }

  const [StateFilters, setStateFilters] = useState(
    [{ name: "vin", value: null, label: null,results:[],display:null },
      { name: "make", value: [], label: null,results:[],display:[] },
      { name: "dealer", value: [], label: null,results:[],display:[] },
      { name: "model", value: [], label: null,results:[],display:[] },
      { name: "trim", value: [], label: null,results:[],display:[] },
      { name: "bodystyle", value: [], label: null,results:[],display:[] },
      { name: "year", value: [store.year[0], store.year[store.year.length - 1]], label: "Year",results:[],display:[] },
      { name: "new", value: [], label: null,results:[],display:[] },
      { name: "bodytype", value: [], label: null,results:[],display:[] },
      { name: "mileage", value: [], label: "0 - "+ numberWithCommas(store.mileage[store.mileage.length - 1]) + "+",results:[],display:[] },
      { name: "cost", value: [], label: "Price",results:[],display:[] },
      { name: "search", value: null, label: null,results:[],display:null },
      { name: "fav", value: null, label: null,results:[],display:null },
      { name: "engine", value: [], label: null,results:[],display:[] },
      { name: "citympg", value: null, label: null,results:[],display:null },
      { name: "hwympg", value: null, label: null,results:[],display:null },
      { name: "drivetrain", value: [], label: null,results:[],display:[] },
      { name: "transmission", value: [], label: null,results:[],display:[] },
      { name: "fuel", value: [], label: null,results:[],display:[] },
      { name: "interiorcolor", value: [], label: null,results:[],display:[] },
      { name: "exteriorcolor", value: [], label: null,results:[] ,display:[]},
      { name: "sorting", value: null, label: null,results:[],display:null },
      { name: "comments5", value: [], label: null,results:[],display:[] },
      { name: "status", value: [], label: null,results:[] ,display:[]}
    ]);
  const [sliderMileageValue, setMileageValue] = useState([0, store.mileage[store.mileage.length - 1]]);
  const [sliderCostValue, setCostValue] = useState([0, MaxCost]);
  const [sliderYearValue, setYearValue] = useState([store.year[0], store.year[store.year.length - 1]]);
  const [filterItems, setfilterItems] = useState({
    Make: {},
    DealerName:{},
    Model: {},
    BodyType: {},
    Year: {},
    Trim:{},
    CityMPG:{},
    HwyMPG:{},
    Drivetrain:{},
    EngineFuelType:{},
    InteriorColor:{},
    GenericExteriorColor:{},
    Engine:{},
    BodyStyle:{},
    Transmission:{}
  })

  const params = new URLSearchParams(pageContentSRP && !adjustedParamCheck ? pageContentSRP:location.search.slice(1))

const getParamResults = (TheIndex) =>{
  let theseResults = []
  const params = new URLSearchParams(pageContentSRP && !adjustedParamCheck ? pageContentSRP:location.search.slice(1))
  const size = Array.from(params).length

  const FiltersToRemoveSpaces = ['dealer','make','model',"bodystyle","trim","bodytype","drivetrain","transmission"]

  const filterHandler = (elm,p) =>{
    let returnVal = p
    if(FiltersToRemoveSpaces.includes(elm)){
        returnVal = p.split(' ').join('').split('-').join('');
    }
    return returnVal
  }
  const minmaxParam = (thefilter,Results) => {
    let queryString = ''
    let min = parseInt(params.get('min-' + thefilter))
    let max = parseInt(params.get('max-' + thefilter))
    let range = thefilter === "mileage" ? MileageRange : (thefilter === "cost" ? CostRange : 1)
    setFilter(thefilter, [min,max])
    min = min + (thefilter === "mileage" ? MileageRange : (thefilter === "cost" ? CostRange : 0))
    for (let i = min; i <= max; i = i + range) {
      queryString = queryString + '' + thefilter + ':' + i + ' '
    }
    params.delete('min-' + thefilter)
    params.delete('max-' + thefilter)
    if(Results.length > 0){
      Results = combineResults(Results,TheIndex.search(queryString))
    }else {
      Results = TheIndex.search(queryString)
    }
    return Results
  }
  let queryString = ''

  if(params.get('min-year') !== null) {
    theseResults =  minmaxParam("year",theseResults)
  }
  if(params.get('min-mileage') !== null) {
    theseResults =  minmaxParam("mileage",theseResults)
  }
  if(params.get('min-cost') !== null) {
    theseResults = minmaxParam("cost",theseResults)
  }

  if (params.get('search') === null && params.get('fav') === null) {
    for (const elm of params.keys()) {
      let thisQueryString = ''
      const elementsIndex = StateFilters.findIndex(con => con.name === elm)
      params.getAll(elm).forEach((param) => {
        if(elm == 'new' && param == 'true,false'){//NEW & USED
          (!IsNew() ? setFilter('new', 'true', 'true') : console.log('IsNew'));
          (!IsUsed() ? setFilter('new', 'false', 'false') : console.log('IsUsed'));
        }else if(elm == 'new' && param == 'true' ){//NEW
          (!IsNew() ? setFilter('new', 'true', 'true') : console.log('IsNew'))
        }else if(elm == 'new' && param == 'false' ){//USED
          (!IsUsed() ? setFilter('new', 'false', 'false') : console.log('IsUsed'))
        }else if(elm == 'certified' && param == 'true,false' ){
            console.log('')
        }else{
          param.split(',').forEach((p) => {
            let filterValue = filterHandler(elm,p)
            if (elementsIndex !== -1) {
              if(elm == 'comment'){
                setFilter(elm, p, p)
                thisQueryString = thisQueryString + '' + elm + ':' + filterValue.split("+").join("plus") + ' '
              }
              else{
                setFilter(elm, p, p)
                thisQueryString = thisQueryString + '' + elm + ':' + filterValue.split("+").join("plus") + ' '
              }
            }
          })
        }
      });
      if(theseResults.length > 0){
        theseResults = combineResults(theseResults,TheIndex.search(thisQueryString))
      }else {
        theseResults = TheIndex.search(thisQueryString)
      }
    }
   /* * * * * * * * * * * * * * * * * * *
    *
    *   New/Used/Certified Logic Matches Findlay Template Logic
    *
    * * * * * * * * * * * * * * * * * * */
   let NUCResults = []
   let thisQuery = ''
   if(IsNew() && IsUsed()){
     thisQuery = '+new:true'
     NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
     thisQuery = '+new:false'
     NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
   }else if(IsNew()){
     thisQuery = '+new:true'
     NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
   }else if(IsUsed()){
     thisQuery = '+new:false'
     NUCResults = NUCResults.concat(TheIndex.search(thisQuery))
   }

   if (theseResults.length > 0 && (IsNew() || IsUsed())){
     theseResults = combineResults(theseResults, NUCResults)
   } else if (IsNew() || IsUsed()){
     theseResults = NUCResults
   }

  }else if (params.get('search') !== null){
    /*search bar Code*/
    let SearchResults = []
    let SearchTypeResults = []
    let Search = false
      let searchValue = params.get("search").split(' ')
      let NewUsedOptions = searchValue.filter(con => { return con.toString().toLowerCase() === "new" || con.toString().toLowerCase() === 'pre-owned' || con.toString().toLowerCase() === 'preowned' || con.toString().toLowerCase() === 'used'});
      let CertifiedOptions = searchValue.filter(con => { return con.toString().toLowerCase() === "certified" || con.toString().toLowerCase() === "cpo" });
      searchValue = searchValue.filter(con => { return con !== "" && (con.toString().toLowerCase() !== "certified" || con.toString().toLowerCase() === "cpo") && (con.toString().toLowerCase() !== "new" || con.toString().toLowerCase() !== 'pre-owned' || con.toString().toLowerCase() !== 'preowned' || con.toString().toLowerCase() !== 'used') })
      // SearchResults = SearchResults.concat(StateIndex.search(searchValue.join('')  + '*'))

      NewUsedOptions.forEach(s => {
        let val = s.toString().toLowerCase();
        if(val === 'pre-owned' || val === 'preowned' || val === 'used') {
          SearchTypeResults = SearchTypeResults.concat(TheIndex.search('new:false'))
        }
        if(val === 'new'){
          SearchTypeResults = SearchTypeResults.concat(TheIndex.search('new:true'))
        }
      });
      CertifiedOptions.forEach(s => {
        SearchTypeResults = SearchTypeResults.concat(TheIndex.search('certified:true'))
      });
      const searchOptions = ['vin','stock','year','model','bodystyle','transmission','fuel','make','trim']
      let OptionResults = []
      searchValue.forEach(s => {
        s = s.split('-').join('').split("+").join("plus")
        let searchOptionResults = []
        searchOptions.forEach( option =>{
          OptionResults = TheIndex.search( option + ':*' + s  + '*')
          if(OptionResults.length > 0){
            searchOptionResults = searchOptionResults.concat(OptionResults)
          }
        })
        if(SearchResults.length == 0){
          SearchResults = searchOptionResults
        }else{
          SearchResults = combineResults(SearchResults,searchOptionResults)
        }
      })
      if(SearchTypeResults.length > 0 && SearchResults.length > 0 ){
        SearchResults = combineResults(SearchTypeResults,SearchResults)
      }else if(SearchTypeResults.length > 0){
        SearchResults = SearchTypeResults
      }

      Search = true
    let hasSearch = (SearchResults.length > 0)

    if (hasSearch) {
      if (theseResults.length > 0 && hasSearch) {
        theseResults = combineResults(theseResults, SearchResults)
      } else if (hasSearch) {
        theseResults = SearchResults
      }
    }
    if(Search && !hasSearch){
      theseResults = [];
    }
    }else{
    let thisQueryString = ''
    getFavorites().forEach((fav) => {
      thisQueryString = thisQueryString + 'vin:' + fav + '* '
    });
      theseResults = TheIndex.search(thisQueryString)
  }
  if(size === 0 ){
    theseResults = TheIndex.search("")
  }
  return theseResults
};

  let allData = {nodes:[]} 
  allData.nodes = data.AllInventory
  let allDataDict = {}

  allData.nodes.forEach((item) => {
    if(item.VehicleInfo.Make == "NONE")
    {
      item.VehicleInfo.Make = " "
    }
    if(item.VehicleInfo.VehicleStatus !="_hidden") {

      allDataDict[item.VehicleInfo.VIN] = item;
    }
  })

  function getDataByKey(key, value) {
    let data = []
    if (key === "bodytype") {
      data = Object.keys(allDataDict).filter((d) => { return allDataDict[d].VehicleInfo.BodyType == value })
    }
    let returnData = []
    data.forEach((d) => {
      returnData = returnData.concat({ ref: d })
    })
    return returnData
  }

  function getFavorites() {
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    return theFavs
  }

  const [StateIndex, setIndex] = useState(Index.load(additionalInventory ? data.AltLunrIndex.index : data.AltLunrIndex.index));
  const [StateResults, setResults] = useState(() => ((getParamResults(StateIndex).filter(result => allDataDict[result.ref] !== undefined))));
  const [StateFavorites, setFavorites] = useState(getFavorites());

  const filterState = {new: IsNew(), used: IsUsed()}
  const itemsPerPage = 10;

  function setFilter(filter, value,display) {

    const elementsIndex = StateFilters.findIndex(con => con.name === filter)

    let label = null;
    let arraryIndex = -1;
    if (filter === "mileage") {
      setMileageValue(value)
      StateFilters[elementsIndex].value = value
      if (value[0] === 0 && value[1] !== store.mileage[store.mileage.length-1]) {
        StateFilters[elementsIndex].label = 'Under ' + numberWithCommas(value[1])
      }else if (value[0] !== 0 && value[1] === store.mileage[store.mileage.length-1]) {
        StateFilters[elementsIndex].label = 'Over ' + numberWithCommas(value[0])
      }
      else {
        StateFilters[elementsIndex].label = numberWithCommas(value[0]) + ' - ' + numberWithCommas(value[1])
      }
    } else if (filter === "year") {
      if(typeof value === 'object') {
        StateFilters[elementsIndex].label = value[0] + ' - ' + value[1]
      }else
      {
        StateFilters[elementsIndex].label = value
        value  = [value,value]
      }

      setYearValue(value)
      StateFilters[elementsIndex].value = value

    }else if (filter === "cost") {
      setCostValue(value)
      StateFilters[elementsIndex].value = value

      if(typeof value === 'object') {

        if (value[0] === 0 && value[1] === MaxCost) {
          StateFilters[elementsIndex].label = 'Price'
        } else if (value[0] === 0 && value[1] !== MaxCost) {
          StateFilters[elementsIndex].label = 'Under ' + formatPrice(value[1])
        }else if (value[0] !== 0 && value[1] === MaxCost) {
          StateFilters[elementsIndex].label = 'Over ' + formatPrice(value[0])
        }
        else {
          StateFilters[elementsIndex].label = formatPrice(value[0]) + ' - ' + formatPrice(value[1]) + (value[1] == MaxCost ? '+' : '')
        }
      }else{
        let value0 = ((parseInt(value) - CostRange))
        let value1 =((parseInt(value)))
        StateFilters[elementsIndex].label = formatPrice(value0) + ' - ' + formatPrice(value1)
      }

    }
    else if (typeof StateFilters[elementsIndex].value === "object" && StateFilters[elementsIndex].value !== null) {

      arraryIndex = StateFilters[elementsIndex].value.indexOf(value)
      if (arraryIndex !== -1) {
        StateFilters[elementsIndex].value.splice(arraryIndex, 1);
        StateFilters[elementsIndex].display.splice(arraryIndex, 1);
      } else {
        if (value === null) {
          StateFilters[elementsIndex].value = []
          StateFilters[elementsIndex].display = []
        } else {
          StateFilters[elementsIndex].value.push(value)
          StateFilters[elementsIndex].display.push(display)
        }
      }
      if (StateFilters[elementsIndex].value.length === 0) {
        StateFilters[elementsIndex].label = null
      } else if (StateFilters[elementsIndex].value.length === 1) {
        StateFilters[elementsIndex].label = StateFilters[elementsIndex].display.length ? StateFilters[elementsIndex].display[0] :  StateFilters[elementsIndex].value[0]
      } else {
        StateFilters[elementsIndex].label = 'Multiple Types Selected'
      }
    } else {
      if (StateFilters[elementsIndex].value == value) { value = null; label = null; }

      StateFilters[elementsIndex].value = value;
      StateFilters[elementsIndex].label = label;
    }
  }

  function getFilterLabel(filter, label) {
    const elementsIndex = StateFilters.findIndex(con => con.name === filter)
    return (StateFilters[elementsIndex].label === null ? label : StateFilters[elementsIndex].label)
  }

  function getDistinctResults(data) {
    let makeDistinct = [];
    let finalResults = [];
    let isInDistint = false
    data.forEach(function (item) {
      isInDistint = makeDistinct.indexOf(item.ref) !== -1
      if (!isInDistint) {
        makeDistinct.push(item.ref)
        finalResults.push(item)
      }
    })
    return finalResults
  }
  function combineResults(FirstResults, SecondResults) {
    let firstArray = []
    let secondArray = []
    let results = []
    FirstResults.forEach((r) => {
      firstArray = firstArray.concat(r.ref)
    })
    SecondResults.forEach((r) => {
      secondArray = secondArray.concat(r.ref)
    })
    results = []
    firstArray.filter(element => secondArray.includes(element)).map((r) => {
      results = results.concat({ ref: r })
    });
    return results
  }

  function getFilterValue(filter, value) {
    const elementsIndex = StateFilters.findIndex(con => con.name === filter)
    return (StateFilters[elementsIndex].value === null ? value : StateFilters[elementsIndex].value)
  }

  function updateFilter(filter, value,display) {
    if (value === "null") {
      value = null
    }
    if(filter === "bodytype" && value === null){
      document.querySelectorAll('.feature__checkbox_checked')
        .forEach((item) => {
          item.classList.toggle('feature__checkbox_checked')
        })
    }
    if (filter !== null) {
      captureEvent({
        event: 'asc_element_configuration',
        page_type: 'Search Results Page',
        event_action_result: 'filter',
        event_action: 'click',
        product_name: '',
        element_state: 'selected',
        element_type: '',
        element_subtype: '',
        element_title: `${filter}`,
        element_text: `${value}`,
        element_value: `${value}`,
        element_color: '#58595b',
        element_position: '',
        element_order: ''
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
      setFilter(filter, value,display);
    }

    let FinalResults = []
    let stateQuery = ''
    let search = StateFilters[StateFilters.findIndex((con) => con.name === "search")]
    let bodytype = StateFilters[StateFilters.findIndex(con => con.name === 'bodytype')]
    StateFilters.filter(con => { return con.value !== null }).forEach(function (item, index) {
      if (item.name !== "search" && item.name !== "sorting") {
        if (typeof item.value === "string") {
          //getResultsFromIndex(item.name,item.value.toLowerCase())
          stateQuery = stateQuery + '+' + item.name + ':' + item.value.split(' ').join(' +') + ' '
        } else if (typeof item.value === "object" && item.value !== null) {
          //CodeBelow for object items below only
        }
        else {
          stateQuery = stateQuery + '+' + item.name + ':' + item.value + ' '
        }
      }
    });


    let StateFilterResults = { }
    FinalResults = []
    let EmptyResultSet = false
    StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length > 0 && con.name !== 'new' && con.name !== 'certified'}).forEach(function (item, index) {
      let TheseResults = []
      let thisQuery = ''
        if(item.name === "year"){
          let start = item.value[0]
          let finish = item.value[1]
          for (let i = start; i <= finish; i++ ) {
            thisQuery = stateQuery + '+' + item.name + ':' + i
            TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
          }
        }else if(item.name === "mileage"){
            let start =  item.value[0]
            let finish =  item.value[1]

            if( item.value[0] > 0 ){
              start =  item.value[0] + MileageRange
            }

            for (let i = start; i <= finish; i = i + MileageRange ) {
              thisQuery = stateQuery + '+' + item.name + ':' + i
              TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
            }
        }else if (item.name === "cost"){
              let start =   item.value[0]
              let finish =  item.value[1]

              if( item.value[0] > 0 ){
                start =  item.value[0] + CostRange
              }
              for (let i = start; i <= finish; i = i + CostRange ) {
                thisQuery = stateQuery + '+' + item.name + ':' + i
                TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
              }
        }else if (item.name === "daysinstock"){
          let start =   item.value[0]
          let finish =  item.value[1]

          if( item.value[0] > 0 ){
            start =  item.value[0] + 1
          }
          for (let i = start; i <= finish; i = i + 1 ) {
            thisQuery = stateQuery + '+' + item.name + ':' + i
            TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
          }
        }
        else if (item.name === "comment") {
          thisQuery = stateQuery + '+' + item.name + ':*' + item.value.split("+").join("plus") + '*'
          TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
        }
        else if (typeof item.value === "object" && item.value !== null) {
            item.value.forEach((i) => {
             thisQuery = stateQuery + '+' + item.name + ':' + i.split(' ').join(' +').split('-').join('').split("+").join("plus")
             TheseResults = TheseResults.concat(StateIndex.search(thisQuery))
           })
        }

      StateFilters[StateFilters.findIndex(con => con.name === item.name)].results = TheseResults
      StateFilterResults[item.name] = TheseResults

      EmptyResultSet = (!EmptyResultSet ? (TheseResults.length === 0) : EmptyResultSet)
      if (FinalResults.length > 0 ){
        FinalResults = combineResults(FinalResults, TheseResults)
        EmptyResultSet = (!EmptyResultSet ? (FinalResults.length === 0) : EmptyResultSet)
      } else {
        FinalResults = TheseResults
      }
    });

    /* * * * * * * * * * * * * * * * * * *
      *
      *   New/Used/Certified Logic Matches Findlay Template Logic
      *
      * * * * * * * * * * * * * * * * * * */
    let NUCResults = []
    let thisQuery = ''
    if(IsNew() && IsUsed()){
      thisQuery = stateQuery + '+new:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
      thisQuery = stateQuery + '+new:false'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }else if(IsNew()){
      thisQuery = stateQuery + '+new:true'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }else if(IsUsed()){
      thisQuery = stateQuery + '+new:false'
      NUCResults = NUCResults.concat(StateIndex.search(thisQuery))
    }

    if(IsNew() || IsUsed()){
      StateFilters[StateFilters.findIndex(con => con.name === 'new')].results = NUCResults
      StateFilterResults['new'] = NUCResults
    }
    if (FinalResults.length > 0 && (IsNew() || IsUsed())){
      FinalResults = combineResults(FinalResults, NUCResults)
      EmptyResultSet = (!EmptyResultSet ? (FinalResults.length === 0) : EmptyResultSet)
    } else if ( IsNew() || IsUsed()){
      FinalResults = NUCResults
    }

    /*MultipleType Code*/

    /*search bar Code*/
    let SearchResults = []
    let SearchTypeResults = []
    let Search = false
    if (search.value !== null && search.value !== '') {
      let searchValue = search.value.split(' ')
      let NewUsedOptions = searchValue.filter(con => { return con.toString().toLowerCase() === "new" || con.toString().toLowerCase() === 'pre-owned' || con.toString().toLowerCase() === 'preowned' || con.toString().toLowerCase() === 'used'});
      let CertifiedOptions = searchValue.filter(con => { return con.toString().toLowerCase() === "certified" || con.toString().toLowerCase() === "cpo" });
      searchValue = searchValue.filter(con => { return con !== "" && (con.toString().toLowerCase() !== "certified" || con.toString().toLowerCase() === "cpo") && (con.toString().toLowerCase() !== "new" || con.toString().toLowerCase() !== 'pre-owned' || con.toString().toLowerCase() !== 'preowned' || con.toString().toLowerCase() !== 'used') })
     // SearchResults = SearchResults.concat(StateIndex.search(searchValue.join('')  + '*'))

      NewUsedOptions.forEach(s => {
        let val = s.toString().toLowerCase();
        if(val === 'pre-owned' || val === 'preowned' || val === 'used') {
          SearchTypeResults = SearchTypeResults.concat(StateIndex.search('new:false'))
        }
        if(val === 'new'){
          SearchTypeResults = SearchTypeResults.concat(StateIndex.search('new:true'))
        }
      });
      CertifiedOptions.forEach(s => {
        SearchTypeResults = SearchTypeResults.concat(StateIndex.search('certified:true'))
      });
      const searchOptions = ['vin','stock','year','model','bodystyle','transmission','fuel','make','trim']
      let OptionResults = []
      searchValue.forEach(s => {
        s = s.split('-').join('').split("+").join("plus")
        let innerSearchResults = []
        searchOptions.forEach( option =>{
          OptionResults = StateIndex.search( option + ':*' + s  + '*')
          if(OptionResults.length > 0){
            innerSearchResults = innerSearchResults.concat(OptionResults)
          }
        })
        if(SearchResults.length == 0){
          SearchResults = innerSearchResults
        }else{
          SearchResults = combineResults(SearchResults,innerSearchResults)
        }
      })

      if(SearchTypeResults.length > 0 && SearchResults.length > 0 ){
        SearchResults = combineResults(SearchTypeResults,SearchResults)
      }else if(SearchTypeResults.length > 0){
        SearchResults = SearchTypeResults
      }


      Search = true
    }

    let hasSearch = (SearchResults.length > 0)

    if (hasSearch) {
      if (FinalResults.length > 0 && hasSearch) {
        FinalResults = combineResults(FinalResults, SearchResults)
      } else if (hasSearch) {
        FinalResults = SearchResults
      }
    }
    if(Search && !hasSearch){
      FinalResults = [];
    }

    if(EmptyResultSet){
      FinalResults = [];
    }else{
      if(FinalResults.length == 0 ){
       //uncomment to make all results to return if no results return
        // commenting out for NIS-574
        // FinalResults = FinalResults.concat(StateIndex.search(""))
      }
    }

    setupFilterDataResults(FinalResults,false)

    setResults(getDistinctResults(FinalResults))
    setSearchParams()

    sortingItems('Price', getStorageItem('sorting:Price'), getStorageItem('sorting:Title'))



  }

  function setFavoriteItems(vin) {
    return (StateFavorites.findIndex((v) => (v == vin)) === -1 ? 'like-btn like-btn-' + vin : 'like-btn  like-btn-' + vin + ' like-btn_active')
  }
  function SearchValueCheck(NewSearchValue){
    let Search = StateFilters[StateFilters.findIndex((con) => con.name === "search")]
    return Search.value == NewSearchValue
  }
  function IsNew(){
    let IsNew = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    return IsNew.value.includes("true")
  }
  function IsUsed(){
    let IsNew = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    return IsNew.value.includes("false")
  }
  function getHeaderLabel(){
    let header =  ""
    let IsNew = StateFilters[StateFilters.findIndex((con) => con.name === "new")]
    let Make = StateFilters[StateFilters.findIndex((con) => con.name === "make")]
    header = (IsNew.value.includes("true") ? "New " : "")
    header = header + (IsNew.value.includes("true") && IsNew.value.includes("false") ? "&" :"")
    header = header + (IsNew.value.includes("false") ? " Pre-Owned " :"")
    header = header + (IsNew.value.length === 0 ? "New & Pre-Owned " : "")

    let MakeLabel = Make.label && Make.label.charAt(0).toUpperCase() + Make.label.slice(1)
    header = header + (Make.value.length == 1 ? MakeLabel + " " :"")

    return header
  }

  function favoriteItem(vin) {
    document.querySelector('.like-btn-' + vin).classList.toggle('like-btn_active')
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    theFavs = theFavs.filter((value, index, self) => self.indexOf(value) === index)
    if (!theFavs.includes(vin)) {          //checking weather array contain the id
      theFavs.push(vin)
    } else {
      theFavs.splice(theFavs.indexOf(vin), 1);  //deleting
    }
    setStorageItem("favoriteItem", JSON.stringify(theFavs));
    setFavorites(theFavs);
  }

  function insertUrlParamSorting(key, value, title) {
    const params = pageContentSRP && !adjustedParamCheck ? new URLSearchParams(pageContentSRP) : new URLSearchParams(location.search);
    const size = Array.from(params).length
    const keys = [];
    if(key !== null){
      const deleteArray = ['sortPrice', 'sortYear', 'sortMileage', 'sortStock', 'inStock','SortPrice', 'SortYear', 'SortMileage', 'SortStock', 'InStock', 'sortDealer', 'SortDealer']
      for(var i = 0; i < deleteArray.length; i++){
        const url = window.location.href
        var r = new URL(url)
        const beforeURL = r.href
        r.searchParams.delete(deleteArray[i])
        const newUrl = r.href
        insertUrlParam(key, value);
        setSortingItems(key, value, title);
        if(beforeURL !=newUrl ){
          history.replaceState({ path: newUrl }, '', newUrl)
        }
      }
    }else{



      for (const elm of params.keys()) {
        params.getAll(elm).forEach((param) => {
          if(elm == 'inStock' || elm == 'InStock' ){
            if(param == 'Newest'){
              keys.push({key:elm, value:param, title: "Days In Stock - Newest First"});
            }else if(param == 'Oldest'){
              keys.push({key:elm, value:param, title: "Days In Stock - Oldest First"});
            }
          }
          if(elm == 'sortPrice' || elm == 'SortPrice'  ){
            if(param == 'Highest'){
              keys.push({key:elm, value:param, title: "Price - Highest First"});
            }else if(param == 'Lowest'){
              keys.push({key:elm, value:param, title: "Price - Lowest First"});
            }
          }
          if(elm == 'sortYear' || elm == 'SortYear' ){
            if(param == 'Newest'){
              keys.push({key:elm, value:param, title: "Year - Newest First"});
            }else if(param == 'Oldest'){
              keys.push({key:elm, value:param, title: "Year - Oldest First"});
            }
          }
          if(elm == 'sortMileage' || elm == 'SortMileage'){
            if(param == 'Least'){
              keys.push({key:elm, value:param, title: "Mileage - Least First"});
            }else if(param == 'Most'){
              keys.push({key:elm, value:param, title: "Mileage - Most First"});
            }
          }
          if(elm == 'sortStock' || elm == 'SortStock' ){
            if(param == 'Stock'){
              keys.push({key:elm, value:param, title: "In Stock - In Transit"});
            }else if(param == 'Transit'){
              keys.push({key:elm, value:param, title: "In Transit - In Stock"});
            }
          }
          //ADD LOCATION SORT HERE DEALER SORT __________________________________________________
          if(elm == 'sortDealer' || elm == 'SortDealer' ){
            keys.push({key:elm, value:param, title: "Sort By Dealership"});
          }
        });
      }
      const keyPos = keys.length;
      if(keyPos > 0 ){
        insertUrlParam(keys[keyPos - 1].key, keys[keyPos - 1].value)
        setSortingItems(keys[keyPos - 1].key, keys[keyPos - 1].value, keys[keyPos - 1].title);
      }
      if(keyPos > 1 ){
        console.log('THERE ARE TWO PARAMS')
        insertUrlParam(keys[1].key, keys[1].value);
        sortKey.current = keys[0].key;
        sortValue.current = keys[0].value;
        sortTitle.current = keys[0].title;
        secondarySortKey.current = keys[1].key
        secondarySortValue.current = keys[1].value
        secondarySortTitle.current = keys[1].title
      }
    }
  }
  const returnSortParams = (key, value, title) => {
    sortKey.current = key;
    sortValue.current = value;
    sortTitle.current = title;
    return {key, value, title}
  }

  function setSortingItems(key, value, title) {
    setStorageItem("sorting:Title", title)
    setStorageItem("sorting:Price", value)
    setStorageItem("sorting:Key", key)

    setResults(sortingItems(key, value, title))
    updateFilter(null, null)
  }

  function sortTheItems(res) {
    let value = getStorageItem('sorting:Price')
    let title = getStorageItem('sorting:Title')
    let key = getStorageItem('sorting:Key')

        if (key === 'Price') {
          res = res.sort(function(a, b) {
            let itemA = allDataDict[a.ref]
            let itemB = allDataDict[b.ref]
            
            if (value == 'Lowest') {
              return itemA.Pricing.ExtraPrice1 - itemB.Pricing.ExtraPrice1
            } else  {
              return itemB.Pricing.ExtraPrice1 - itemA.Pricing.ExtraPrice1
            }
          })
        }

        if (key === 'Year') {
          res = res.sort(function(a, b) {
            let itemA = allDataDict[a.ref]
            let itemB = allDataDict[b.ref]
              if (value == 'Newest') {
                return itemB.VehicleInfo.Year - itemA.VehicleInfo.Year
              } else if (value == 'Oldest') {
                return  itemA.VehicleInfo.Year - itemB.VehicleInfo.Year
              } else {
                return convertStatus(itemA.VehicleInfo.VehicleStatus) - convertStatus(itemB.VehicleInfo.VehicleStatus)
              }
    
          })
        }

        if(key == 'Mileage'){
          res = res.sort(function(a, b) {
            let itemA = allDataDict[a.ref]
            let itemB = allDataDict[b.ref]
    
    
            if (value == 'Lowest') {
              return  itemA.VehicleInfo.Mileage - itemB.VehicleInfo.Mileage
    
            } else {
              return  itemB.VehicleInfo.Mileage - itemA.VehicleInfo.Mileage
            }
    
          })
        }

        if(key == 'Stock'){
          res = res.sort(function(a, b) {
    
            let itemA = allDataDict[a.ref]
            let itemB = allDataDict[b.ref]
    
            let itemConvertedA = convertStatus(itemA.VehicleInfo.VehicleStatus);
            let itemConvertedB = convertStatus(itemB.VehicleInfo.VehicleStatus);
    
            if (value == 'In') {
    
              return itemConvertedA - itemConvertedB
            } else {
              return itemConvertedB - itemConvertedA
            }
    
          })
        }

        if(key == 'Date' ){
          res = res.sort(function(a, b) {
            let itemA = allDataDict[a.ref]
            let itemB = allDataDict[b.ref]
    
            let itemADate = new Date(itemA.VehicleInfo.InStockDate);
            let itemBDate = new Date(itemB.VehicleInfo.InStockDate);
    
            if (value == 'Newest') {
              return itemADate - itemBDate
            } else {
              return itemBDate - itemADate
            }
    
          })
        }

        if(additionalInventory && soldSort) {
          res = res.sort(function(a, b) {
            let itemA = allDataDict[a.ref]
            let itemB = allDataDict[b.ref]
  
            // Convert SoldDate strings to Date objects
            let dateA = new Date(itemA.SoldDate);
            let dateB = new Date(itemB.SoldDate);
            // Compare dates
            return dateB.getTime() - dateA.getTime();
          })
        }

        if(key === null  && usePriority) {
          //this is where make priority logic goes
          res = res.sort(function(a,b){
            let itemA = allDataDict[a.ref]
            let itemB = allDataDict[b.ref]

            let makeA = convertMake(priorityMake, itemA.VehicleInfo.Make)
            let makeB = convertMake(priorityMake, itemB.VehicleInfo.Make)

            return makeB - makeA;
          })
        }

      try {
        res = res.sort(function(a, b) {
          let itemA = allDataDict[a.ref];
          let itemB = allDataDict[b.ref];

          // Check if PhotoCount is zero for either item
          if (itemA.VehicleInfo.PhotoCount === 0 && itemB.VehicleInfo.PhotoCount === 0) {
            // If both have PhotoCount as 0, maintain their original order
            return 0;
          } else if (itemA.VehicleInfo.PhotoCount === 0) {
            // If itemA has PhotoCount as 0, move it down
            return 1;
          } else if (itemB.VehicleInfo.PhotoCount === 0) {
            // If itemB has PhotoCount as 0, move it up
            return -1;
          } else {
            return 0;
          }
        });
      } catch (e) {
        // Handle errors here if necessary
      }
    return res
  }
  
  function convertStatus(status){
    return ((status == 'In-Transit') ? 1 : 0)
  }

  function convertMake(list, itemMake) {
    let result = 0
    list.forEach((element) => {
      if(element.trim().toLowerCase() === itemMake.trim().toLowerCase()) {
        result = 1
      }
    })
    return result;
  }

  function sortingItems(key, value, title) {
    let res = StateResults

      if (key === 'Price') {
        console.log('sortingItems Price')

        res = res.sort(function(a, b) {
          let itemA = allDataDict[a.ref]
          let itemB = allDataDict[b.ref]
          if (value == 'Lowest') {
            return itemA.Pricing.ExtraPrice1 - itemB.Pricing.ExtraPrice1
          } else {
            return itemB.Pricing.ExtraPrice1 - itemA.Pricing.ExtraPrice1
          }
        })
      }

      if (key === 'Year') {
        res = res.sort(function(a, b) {
          let itemA = allDataDict[a.ref]
          let itemB = allDataDict[b.ref]
            if (value == 'Newest') {
              return itemB.VehicleInfo.Year - itemA.VehicleInfo.Year
            } else if (value == 'Oldest') {
              return  itemA.VehicleInfo.Year - itemB.VehicleInfo.Year
            } else {
              return convertStatus(itemA.VehicleInfo.VehicleStatus) - convertStatus(itemB.VehicleInfo.VehicleStatus)
            }
  
        })
      }

      if(key == 'Mileage'){
        res = res.sort(function(a, b) {
          let itemA = allDataDict[a.ref]
          let itemB = allDataDict[b.ref]
  
  
          if (value == 'Lowest') {
            return  itemA.VehicleInfo.Mileage - itemB.VehicleInfo.Mileage
  
          } else {
            return  itemB.VehicleInfo.Mileage - itemA.VehicleInfo.Mileage
          }
  
        })
      }

      if(key == 'Stock'){
        res = res.sort(function(a, b) {
  
          let itemA = allDataDict[a.ref]
          let itemB = allDataDict[b.ref]
  
          let itemConvertedA = convertStatus(itemA.VehicleInfo.VehicleStatus);
          let itemConvertedB = convertStatus(itemB.VehicleInfo.VehicleStatus);
  
          if (value == 'In') {
  
            return itemConvertedA - itemConvertedB
          } else {
            return itemConvertedB - itemConvertedA
          }
  
        })
      }

      if(key == 'Date' ){
        res = res.sort(function(a, b) {
          let itemA = allDataDict[a.ref]
          let itemB = allDataDict[b.ref]
  
          let itemADate = new Date(itemA.VehicleInfo.InStockDate);
          let itemBDate = new Date(itemB.VehicleInfo.InStockDate);
  
          if (value == 'Newest') {
            return itemADate - itemBDate
          } else {
            return itemBDate - itemADate
          }
  
        })
      }

      if(additionalInventory && soldSort) {
        res = res.sort(function(a, b) {
          let itemA = allDataDict[a.ref]
          let itemB = allDataDict[b.ref]
  
          // Convert SoldDate strings to Date objects
          let dateA = new Date(itemA.SoldDate);
          let dateB = new Date(itemB.SoldDate);
  
          // Compare dates
          return dateA.getTime() - dateB.getTime();
        })
      }

      if(key === null  && usePriority) {
        //this is where make priority logic goes
        res = res.sort(function(a,b){
          let itemA = allDataDict[a.ref]
          let itemB = allDataDict[b.ref]

          let makeA = convertMake(priorityMake, itemA.VehicleInfo.Make)
          let makeB = convertMake(priorityMake, itemB.VehicleInfo.Make)
          return makeB - makeA;
        })
      }

    try {
      res = res.sort(function(a, b) {
        let itemA = allDataDict[a.ref];
        let itemB = allDataDict[b.ref];

        // Check if PhotoCount is zero for either item
        if (itemA.VehicleInfo.PhotoCount === 0 && itemB.VehicleInfo.PhotoCount === 0) {
          // If both have PhotoCount as 0, maintain their original order
          return 0;
        } else if (itemA.VehicleInfo.PhotoCount === 0) {
          // If itemA has PhotoCount as 0, move it down
          return 1;
        } else if (itemB.VehicleInfo.PhotoCount === 0) {
          // If itemB has PhotoCount as 0, move it up
          return -1;
        } else {
          return 0;
        }
      });
    } catch (e) {
      // Handle errors here if necessary
    }
    return res
  }

  function mileageChange(event, newValue) {
    sliderChange(event, newValue, 'mileage', setMileageValue,MileageRange)
    setMileageValue(newValue)
  }
  function costChange(event, newValue) {
    sliderChange(event, newValue, 'cost', setCostValue,CostRange)
    setCostValue(newValue)
  }
  function yearChange(event, newValue) {
    sliderChange(event, newValue, 'year', setYearValue,1)
    setYearValue(newValue)
  }
  function sliderChange(event, newValue, filter, setValue,range) {
    let slide = [];
    slide = slide.concat(Math.round(parseInt(newValue[0]) / range) * range);
    slide = slide.concat(Math.round(parseInt(newValue[1]) / range) * range);
    setValue(slide);
    updateFilter(filter, slide);
  }

  function setupFilterDataResults(theResults,IsStateResults){
    let theFilters = []
    if(IsStateResults){
      theFilters = StateFilters.filter(con => {
        return (con.value === null
                || (typeof con.value === "object"  && con.value.length === 0 )
                || (con.name === "year" && con.label === "Year")
                ||(con.name === "cost" && con.label === "Price")
                ||(con.name === "mileage" && con.label === "0 - 150,000+")) && con.results.length === 0
      })
    }else {
      theFilters = StateFilters.filter(con => {
        return (con.value === null || (typeof con.value === "object"  && con.value.length === 0 ))
      })
    }
    theFilters.forEach(function(item, index) {
      StateFilters[StateFilters.findIndex(con => con.name === item.name)].results = theResults
    });

  }

  function setupFilterData() {
    let filterDataCounts = []
    let filterData = Object.keys(allDataDict).map((key) => allDataDict[key]);

    StateResults.map((result, index) => {
      filterDataCounts.push(allDataDict[result.ref])
    })

    const getMyResults  = (allFilters,filter) => {
      let ReturnResults = StateFilters[StateFilters.findIndex(con => con.name === filter)].results
      let EmptyResultSet = false
      if(allFilters){
        ReturnResults = []
        let theFilters = StateFilters.filter(con => { return con.value !== null &&
          con.value.length > 0 &&
          con.name !== filter})
          theFilters.forEach(function (item, index) {
            EmptyResultSet = (!EmptyResultSet ? (item.results.length === 0) : EmptyResultSet)
            if(ReturnResults.length > 0){
              ReturnResults = combineResults(ReturnResults,item.results)
              EmptyResultSet = (!EmptyResultSet ? (ReturnResults.length === 0) : EmptyResultSet)
            }else{
              ReturnResults = item.results
            }
          });
      }
      if(EmptyResultSet){
        ReturnResults = []
      }
      return ReturnResults
    }

    const setFilterItem = (filterItem,key,filter,theseResults,filterFunction) => {
      if(filterFunction === undefined){
        filterFunction = (item) => { return item }
      }
      let returnItem = {show:true, count:"0" ,selected:false}
     let thisFilterDataCounts = []
      let thisStateResultsDataCounts = []
      theseResults.map((result, index) => {
        if(allDataDict[result.ref] !== undefined)
        thisFilterDataCounts.push(allDataDict[result.ref])
      })
      StateResults.map((result, index) => {
        if(allDataDict[result.ref] !== undefined)
        thisStateResultsDataCounts.push(allDataDict[result.ref])
      })

      let DataCounts = thisFilterDataCounts.filter(item => filterFunction(item.VehicleInfo[filterItem]).toString().split("-").join("") == key).length
      let StateDataCounts = thisStateResultsDataCounts.filter(item => filterFunction(item.VehicleInfo[filterItem]).toString().split("-").join("") == key).length
      let FilterValue =  StateFilters[StateFilters.findIndex(con => con.name === filter)].value

      let thisShow = (FilterValue !== null ? FilterValue.includes(key.toString().split(" ").join("").split("-").join("")) : false)
      returnItem.selected = thisShow
      thisShow = thisShow || (Math.max(StateDataCounts,DataCounts) > 0)
      returnItem.show = thisShow
      returnItem.count = StateDataCounts === 0 ? "" : "(" + StateDataCounts + ")" //DataCounts //Math.min(StateDataCounts,DataCounts)

      return returnItem
    }
    /*let DealerResults =  getMyResults(true,"dealer")
    filterData.map(result => result.VehicleInfo.DealerName).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.DealerName[theKey] = setFilterItem("DealerName",theKey,"dealer",DealerResults)
    });*/
    let MakeResults =  getMyResults(true,"make")
    filterData.map(result => result.VehicleInfo.Make).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      //console.log(key)
     // console.log(theKey)
      filterItems.Make[theKey] = setFilterItem("Make",theKey,"make",MakeResults)
    });
      //console.log(filterItems.Make)

    let ModelResults =  getMyResults(true,"model")
    filterData.map(result => result.VehicleInfo.Model).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      filterItems.Model[theKey] = setFilterItem("Model",theKey,"model",ModelResults)
    });
     
    let BodyTypeResults =   getMyResults(true,"bodytype")
    filterData.map(result => result.VehicleInfo.BodyType).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      filterItems.BodyType[theKey] = setFilterItem("BodyType",theKey,"bodytype",BodyTypeResults)
    });

    let YearResults =  getMyResults(true,"year")
    filterData.map(result => result.VehicleInfo.Year).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.Year[theKey] =  setFilterItem("Year",theKey,"year",YearResults)
    });

    let EngineResults =  getMyResults(true,"engine")
    filterData.map(result => result.VehicleInfo.Engine).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      filterItems.Engine[theKey] = setFilterItem("Engine",theKey,"engine",EngineResults)
    });

    let CityMPGResults =  getMyResults(true,"citympg")
    filterData.map(result => result.VehicleInfo.CityMPG).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.CityMPG[theKey] = setFilterItem("CityMPG",theKey,"citympg",CityMPGResults)
    });

    let HwyMPGResults =  getMyResults(true,"hwympg")
    filterData.map(result => result.VehicleInfo.HwyMPG).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.HwyMPG[theKey] = setFilterItem("HwyMPG",theKey,"hwympg",HwyMPGResults)
    });

    let TrimResults =   getMyResults(true,"trim")
    filterData.map(result => result.VehicleInfo.Trim).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      filterItems.Trim[theKey] = setFilterItem("Trim",theKey,"trim",TrimResults)
    });

    let BodyStyleResults =   getMyResults(true,"bodystyle")
    filterData.map(result => result.VehicleInfo.BodyStyle).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      filterItems.BodyStyle[theKey] = setFilterItem("BodyStyle",theKey,"bodystyle",BodyStyleResults)
    });

    let DrivetrainResults =  getMyResults(true,"drivetrain")
    filterData.map(result => result.VehicleInfo.Drivetrain).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      filterItems.Drivetrain[theKey] = setFilterItem("Drivetrain",theKey,"drivetrain",DrivetrainResults)
    });

    let EngineFuelTypeResults =  getMyResults(true,"fuel")
    filterData.map(result => result.VehicleInfo.EngineFuelType).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = getFuelType(key)
      filterItems.EngineFuelType[theKey] = setFilterItem("EngineFuelType",theKey,"fuel",EngineFuelTypeResults,getFuelType)
    });

    let TransmissionResults =  getMyResults(true,"transmission")
    filterData.map(result => result.VehicleInfo.Transmission).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      filterItems.Transmission[getTransmissionType(theKey)] = setFilterItem("Transmission",getTransmissionType(theKey),"transmission",TransmissionResults,getTransmissionType)
    });

    let InteriorColorResults =  getMyResults(true,"interiorcolor")
    filterData.map(result => result.VehicleInfo.InteriorColor).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key
      filterItems.InteriorColor[getColor(theKey)] = setFilterItem("InteriorColor",getColor(theKey),"interiorcolor",InteriorColorResults,getColor)
    });

    let GenericExteriorColorResults =  getMyResults(true,"exteriorcolor")
    filterData.map(result => result.VehicleInfo.GenericExteriorColor).filter((value, index, self) => self.indexOf(value) === index && value !== "" && value != null && value !== undefined).forEach((key) => {
      let theKey = key.toString().split("-").join("")
      filterItems.GenericExteriorColor[getColor(theKey)] = setFilterItem("GenericExteriorColor",getColor(theKey),"exteriorcolor",GenericExteriorColorResults,getColor)
    });
  }


  function msrpClass(amount){
    let theClass = "sr__costs"
    if(amount == 0){
      theClass = theClass + " msrp__hide"
    }
    return theClass
  }

  function insertUrlParam(key, value) {
    if (history.pushState) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      if (!searchParams.has('fq', "1") && searchParams.toString() != "=" ) {
        searchParams.set('fq', "1");
      }  
     let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
     history.replaceState({path: newurl}, '', newurl);
    }
  }
  function getSearchParams(){
    let newurl = '?'
    if (history.pushState) {
      let searchParams = new URLSearchParams(pageContentSRP && !adjustedParamCheck ? pageContentSRP:window.location.search);
      newurl = '?' + searchParams.toString();
    }
    return newurl
  }
  function removeUrlParameter(paramKey) {
    const url = window.location.href
    var r = new URL(url)
    const beforeURL = r.href
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    if(beforeURL !=newUrl ){
      history.replaceState({ path: newUrl }, '', newUrl)
    }
  }

  function setSearchParams() {
    if ('URLSearchParams' in window) {
      let searchParams = new URLSearchParams(pageContentSRP && !adjustedParamCheck ? pageContentSRP:window.location.search);

      StateFilters.filter(con => { return con.value === null }).forEach(function (item, index) {
        removeUrlParameter(item.name)
      });

      StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null 
      && con.value.length == 0 }).forEach(function (item, index) {
        removeUrlParameter(item.name)
      });

      StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length > 0 }).forEach(function (item, index) {
        if(!["price","cost","mileage","year","daysinstock"].includes(item.name)){
        searchParams.set(item.name, item.value);
          insertUrlParam(item.name, item.value);
        }else{
            insertUrlParam("min-" + item.name, item.value[0]);
            insertUrlParam("max-" + item.name, item.value[1]);
        }
      });
      StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"}).forEach(function (item, index) {
        insertUrlParam(item.name, item.value.trim());
      });
    }

  }

  function resetSearchPage(){
    let theParams = []
    for (const elm of params.keys()) {
      theParams = theParams.concat(elm)
    }
    //resets any filter that isn't an object, back to
    StateFilters.filter(con => { return typeof con.value !== "object" && con.value !== null }).map((f) => {
      if(!theParams.includes(f.name)){
        if(!["price","cost","mileage","year"].includes(f.name)){
          setFilter(f.name,f.value)
        }
      }
    })
    StateFilters.filter(con => { return typeof con.value === "object" && con.value !== null && con.value.length > 0 }).map((f) => {
      if(!theParams.includes(f.name)){
        if(!["price","cost","mileage","year"].includes(f.name)){
          if(f.value.length > 0){
            f.value.forEach((v) => { setFilter(f.name,v) })
          }
        }
      }else{
        let theParamValue = params.getAll(f.name)
        f.value.forEach((v) => { if(!theParamValue.includes(v)) {setFilter(f.name,v) }})
      }
    })
    if(document.querySelector('.open-filters-srp-page-wrap_active') !== null){
      document.querySelector('.open-filters-srp-page-wrap_active').classList.toggle('open-filters-srp-page-wrap_active');
    }
    if(document.querySelector('.open-filters-btn__text_active') !== null){
      document.querySelector('.open-filters-btn__text_active').classList.toggle('open-filters-btn__text_active');
    }
    if(document.querySelector('.filters-wrap_visible') !== null){
      document.querySelector('.filters-wrap_visible').classList.toggle('filters-wrap_visible')
    }
    setQueryState(SearchParam)
    setResults(getParamResults(StateIndex))
  }
  useEffect(() => {
    //same query not reset needed
    if((queryState === SearchParam) && !adjustedParamCheck) return
    //if the query params change reset the search page to work
    resetSearchPage()
  },[SearchParam])

  setupFilterDataResults(StateResults,true);
  setupFilterData()

  const [isOpen, toggleOpen] = useState([false, false, false,false,false,false,false])
  const toggleMenu = (idx) => {
    const _isOpen = isOpen.map((val, jdx) => {
      return idx == jdx ? !val : false
    })
    if(idx === 3){
      document.querySelector('.more-filters').classList.toggle('more-filters_visible')
    }
    toggleOpen(_isOpen)
  }

  const [isMoreOpen, toggleMoreOpen] = useState([false, false, false,false,false])
  const toggleMoreMenu = (idx) => {
    const _isMoreOpen = isMoreOpen.map((val, jdx) => {
      return idx == jdx ? !val : false
    })
    toggleMoreOpen(_isMoreOpen)
  }

  function toggleClass(mainClassName,toggleClassName,checkedClassName){

    /*document.querySelectorAll('.'+mainClassName+'.'+checkedClassName)
      .forEach((item) => {
        if(!item.classList.contains(toggleClassName)){
          item.classList.toggle(checkedClassName)
        }
      })*/

    document.querySelector('.' + toggleClassName).classList.toggle(checkedClassName)
  }
  const hideMenus = () => {
    const _isOpen = isOpen.map(() => (false))
    toggleOpen(_isOpen)
    //document.querySelector('.more-filters').classList.remove('more-filters_visible')
  }
  function getFilterItems(Item){
    return Object.keys(filterItems[Item]).sort().filter((r) => filterItems[Item][r].show)
  }
  
  return (
    <Layout seo={pageSeo} footerClassName={"srp-footer"}>
	  <div className="srp-page-wrap">
    {pageContext?.pageContent?.SRP?.disableTopHtml?
        null
        :
        <InnerHTML html={topHtmlSRP} />
      }
      <InnerHTML html={staticTopHtmlSrp} />
        <button className="open-filters-btn"
      onClick={() => { document.querySelector('.srp-page-wrap').classList.toggle('open-filters-srp-page-wrap_active');  document.querySelector('.open-filters-btn').classList.toggle('open-filters-btn__text_active');  document.querySelector('.content-wrap').classList.toggle('filters-wrap_visible');window.scrollTo(0,0);}}>
          <span className="open-filters-btn__text"><span className="open">Open</span> <span className="close">Close</span>  Filters</span>
        </button>
      {/* <div className="filters-wrap">

          <ul className="srp-features">
            <li className="srp-features__item srp-features__cooled-seats"
                onClick={() => { document.querySelector('.srp-features__cooled-seats').classList.toggle('srp-feature_active') }}>
              Cooled Seats
            </li>
            <li className="srp-features__item srp-features__heated-seats"
                onClick={() => { document.querySelector('.srp-features__heated-seats').classList.toggle('srp-feature_active')}}>
              Heated Seats
            </li>
            <li className="srp-features__item srp-features__leather-interior"
                onClick={() => { document.querySelector('.srp-features__leather-interior').classList.toggle('srp-feature_active')}}>Leather Interior
            </li>
            <li className="srp-features__item srp-features__sun-roof"
                onClick={() => { document.querySelector('.srp-features__sun-roof').classList.toggle('srp-feature_active')}}>Sun
              Roof
            </li>
            <li className="srp-features__item srp-features__blind-spot"
                onClick={() => { document.querySelector('.srp-features__blind-spot').classList.toggle('srp-feature_active')}}>
              Blind Spot Sensors
            </li>
            <li className="srp-features__item srp-features__lane-departure"
                onClick={() => { document.querySelector('.srp-features__lane-departure').classList.toggle('srp-feature_active')}}>
              Lane Departure Warning
            </li>
            <li className="srp-features__item srp-features__park-assist"
                onClick={() => { document.querySelector('.srp-features__park-assist').classList.toggle('srp-feature_active')}}>
              Park Assist
            </li>
          </ul>
          */}
          {/*
          <ul className="srp-filters">
            <li className="dropdown-v1 srp-dropdown__condition" onMouseLeave={hideMenus} onClick={() => toggleMenu(0)}>
              <div className="sr-dropdown__inner">
                <p className="sr-dropdown__name">{getFilterLabel('new', 'Condition')}</p>
              </div>
              {isOpen[0] &&
              (<ul className="sub-dropdown-v1 search-dropdown" onClick={() => toggleMenu(0)}>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('new', null) }}>All Conditions</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('new', 'true') }}>New</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('new', 'false') }}>Used</li>
              </ul>)
              }
            </li>
            <li className="dropdown-v1 srp-dropdown__price" onMouseLeave={hideMenus} onClick={() => toggleMenu(2)}>
              <div className="sr-dropdown__inner">
                <p className="sr-dropdown__name">{getFilterLabel('cost', 'Price')}</p>
              </div>
              {isOpen[2] &&
              <ul className="sub-dropdown-v1 search-dropdown" onClick={() => toggleMenu(2)}>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [0,150000]) }}>All Prices</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [0,10000]) }}>$0-$10,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [10000,20000]) }}>$10,000-$20,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [20000,30000]) }}>$20,000-$30,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [30000,40000]) }}>$30,000-$40,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [40000,50000]) }}>$40,000-$50,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [50000,60000]) }}>$50,000-$60,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [60000,70000]) }}>$60,000-$70,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [70000,80000]) }}>$70,000-$80,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [80000,90000]) }}>$80,000-$90,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [90000,100000]) }}>$90,000-$100,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [100000,110000]) }}>$100,000-$110,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [110000,120000]) }}>$110,000-$120,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [120000,130000]) }}>$120,000-$130,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [130000,140000]) }}>$130,000-$140,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [140000,150000]) }}>$140,000-$150,000</li>
                <li className="sub-dropdown-v1__item" onClick={() => { updateFilter('cost', [150000,150000]) }}>$150,000+</li>
              </ul>
              }
            </li>

            <li className="dropdown-v1 srp-dropdown__model" onMouseLeave={hideMenus}  onClick={() => toggleMenu(1)}>
              <div className="sr-dropdown__inner">
                <p className="sr-dropdown__name">{getFilterLabel('model', 'Model')}</p>
              </div>
              {isOpen[1] &&
              (<ul className="sub-dropdown-v1 search-dropdown">
                <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(1); updateFilter('model', null) }}>All Models</li>
                {Object.keys(filterItems.Model).sort().filter((r) => filterItems.Model[r] > 0).map(Model => {
                  return (
                    <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(1); updateFilter('model', Model) }}>
                      {Model} ({filterItems.Model[Model]})
                    </li>
                  )
                })}
              </ul>)
              }
            </li>
            <li className="dropdown-v1 srp-dropdown__model" onMouseLeave={hideMenus}  onClick={() => toggleMenu(4)}>
              <div className="sr-dropdown__inner">
                <p className="sr-dropdown__name">{getFilterLabel('year', 'Year')}</p>
              </div>
              {isOpen[4] &&
              (<ul className="sub-dropdown-v1 search-dropdown">
                <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(4); updateFilter('year', null) }}>All Years</li>
                {Object.keys(filterItems.Year).sort().filter((r) => filterItems.Year[r] > 0).map(Year => {
                  return (
                    <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(4); updateFilter('year', Year) }}>
                      {Year} ({filterItems.Year[Year]})
                    </li>
                  )
                })}
              </ul>)
              }
            </li>
            <li className="sr-dropdown more-filters-btn" onClick={() => toggleMenu(3)}>
              <div className="sr-dropdown__inner">
                <p className="sr-dropdown__name">More filters</p>
              </div>
            </li>

            <div className="more-filters" onMouseLeave={hideMenus}>
              <span className="more-filters-exit" onClick={() => toggleMenu(3)}>X</span>
              <div className="more-filters__row">
                <div className="more-filters__fuel-drivetrain-wrap">
                  <fieldset className="options-list">
                    <h2 className="options-list__header">Fuel</h2>
                    <div className="options-list__item-container">
                      {Object.keys(filterItems.EngineFuelType).sort().map(EngineFuelType => {
                        let toggleClassName = "fuel-" + EngineFuelType.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-') + "__item"
                        let checkedClassName = "options-list__item_checked"
                        let mainClassName = "fuel.options-list__item"
                        return (
                          <label className={"fuel options-list__item " + toggleClassName }>
                            <input type="checkbox" className="options-list__checkbox"
                                   onClick={() => {updateFilter('fuel', EngineFuelType); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list__text">{EngineFuelType}</span>
                          </label>
                        )
                      })}
                    </div>
                  </fieldset>

                  <div className="more-filters__row">
                    <fieldset className="options-list options-list_transmissions">
                      <h2 className="options-list__header">Transmissions</h2>
                      <div className="options-list__item-container">
                        {Object.keys(filterItems.Transmission).sort().map(Transmission => {
                          let toggleClassName = "transmission-" + Transmission.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-') + "__item"
                          let checkedClassName = "options-list__item_checked"
                          let mainClassName = "transmission.options-list__item"
                          return (
                            <label className={"transmission options-list__item " + toggleClassName}>
                              <input type="checkbox" className="options-list__checkbox"
                                     onClick={() => {updateFilter('transmission', Transmission); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                              <span className="options-list__text">{Transmission}</span>
                            </label>
                            )
                        })}
                      </div>
                    </fieldset>

                    <fieldset className="options-list">
                      <h2 className="options-list__header">Drivetrain</h2>
                      <div className="options-list__item-container">
                        {Object.keys(filterItems.Drivetrain).sort().map(Drivetrain => {
                          let toggleClassName = "drivetrain-" + Drivetrain.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-') +"_item"
                          let checkedClassName = "options-list__item_checked"
                          let mainClassName = "drivetrain.options-list__item"
                          return (
                          <label className={"drivetrain options-list__item " + toggleClassName}>
                            <input type="checkbox" className="options-list__checkbox"
                                  onClick={() => {updateFilter('drivetrain', Drivetrain); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                            <span className="options-list__text">{Drivetrain}</span>
                          </label>
                          )
                        })}
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="more-filters__colors-wrap">
                  <div className="color-list">
                    <h2 className="color-list__header">Exterior Color</h2>
                    <div className="color-list__item-container">
                      <label className="color-list__item color-list_f9f9f9 exterior">
                        <input type="checkbox" className="color-list__checkbox"
      onClick={() => {updateFilter('exteriorcolor', 'White'); document.querySelector('.color-list_f9f9f9.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_b5b5b5 exterior">
                        <input type="checkbox" className="color-list__checkbox"
      onClick={() => {updateFilter('exteriorcolor', 'Gray'); document.querySelector('.color-list_b5b5b5.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_9e0000 exterior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', 'Red'); document.querySelector('.color-list_9e0000.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_262626 exterior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', 'Black'); document.querySelector('.color-list_262626.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_eacc07 exterior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', 'Yellow'); document.querySelector('.color-list_eacc07.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_b6d7ed exterior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', 'Light Blue'); document.querySelector('.color-list_b6d7ed.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_db6200 exterior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', 'Orange'); document.querySelector('.color-list_db6200.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_0d8fe6 exterior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', 'Dark Blue'); document.querySelector('.color-list_0d8fe6.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_55271c exterior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', 'Brown'); document.querySelector('.color-list_55271c.exterior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                    </div>
                  </div>

                  <div className="color-list">
                    <h2 className="color-list__header">Interior Color</h2>
                    <div className="color-list__item-container">
                      <label className="color-list__item color-list_f9f9f9 interior">
                        <input type="checkbox" className="color-list__checkbox"
      onClick={() => {updateFilter('interiorcolor', 'White'); document.querySelector('.color-list_f9f9f9.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_b5b5b5 interior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('interiorcolor', 'Gray'); document.querySelector('.color-list_b5b5b5.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_9e0000 interior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('interiorcolor', 'Red'); document.querySelector('.color-list_9e0000.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_262626 interior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('interiorcolor', 'Black'); document.querySelector('.color-list_262626.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_eacc07 interior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('interiorcolor', 'Yellow'); document.querySelector('.color-list_eacc07.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_b6d7ed interior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('interiorcolor', 'Light Blue'); document.querySelector('.color-list_b6d7ed.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_db6200 interior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('interiorcolor', 'Orange'); document.querySelector('.color-list_db6200.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_0d8fe6 interior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('interiorcolor', 'Dark Blue'); document.querySelector('.color-list_0d8fe6.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                      <label className="color-list__item color-list_55271c interior">
                        <input type="checkbox" className="color-list__checkbox"
                               onClick={() => {updateFilter('interiorcolor', 'Brown'); document.querySelector('.color-list_55271c.interior').classList.toggle('color-list__item_checked')}}/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <fieldset className="options-list">
                <h2 className="options-list__header">Body Style</h2>
                <div className="options-list__item-container">
                  {Object.keys(filterItems.BodyStyle).sort().map(BodyStyle => {
                    let toggleClassName = "bodystyle-" + BodyStyle.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-') +"__item"
                    let checkedClassName = "options-list__item_checked"
                    let mainClassName = "bodystyle.options-list__item"
                    return (
                      <label className={"bodystyle options-list__item " + toggleClassName}>
                        <input type="checkbox" className="options-list__checkbox"
                               onClick={() => {updateFilter('bodystyle', BodyStyle); toggleClass(mainClassName,toggleClassName,checkedClassName) }}/>
                        <span className="options-list__text">{BodyStyle}</span>
                      </label>
                    )
                  })}
                </div>
              </fieldset>
	  <div className="trim-mileage__row">
              <div className="more-filters__row">
                <fieldset className="options-list">
                  <h2 className="options-list__header">Trim</h2>
                  <div className="options-list__item-container">
                    {Object.keys(filterItems.Trim).sort().map(Trim => {
                      let toggleClassName = "trim-" + Trim.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-') + "__item"
                      let checkedClassName = "options-list__item_checked"
                      let mainClassName = "trim.options-list__item"
                      return (
                      <label className={"trim options-list__item " + toggleClassName}>
                        <input type="checkbox" className="options-list__checkbox"
                      onClick={() => {updateFilter('trim', Trim); toggleClass(mainClassName,toggleClassName,checkedClassName) }}/>
                        <span className="options-list__text">{Trim}</span>
                      </label>
                      )
                    })}
                  </div>
                </fieldset>
              </div>
                <div className="range-slider">
                  <div className="range-slider__row" style={{color: colorTheme.accent2}}>
                    <h2 className="range-slider__header">Mileage</h2>
                    <p className="range-slider__mileage">{getFilterLabel('mileage', '0')}</p>
                  </div>
                  <Slider
                    value={sliderMileageValue}
                    onChange={mileageChange}
                    // valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    steps={MileageRange}
                    min={0}
                    max={150000}
                  />
          </div>
	  </div>
              <ul className="more-filters__row">


                <li className="dropdown-v1 srp-dropdown__engine" onMouseLeave={hideMenus} onClick={() => toggleMoreMenu(0)}>
                  <h2 className="dropdown-v2__header">Engine</h2>
                  {isMoreOpen[0] &&
                  (<ul className="sub-dropdown-v2 search-dropdown">
                      <li className="sub-dropdown-v1__item" onClick={() => { updateFilter(null, null) }}>All Engines</li>
                      {Object.keys(filterItems.Engine).sort().filter((r) => filterItems.Engine[r] > 0).map(Engine => {
                        return (
                          <li className="sub-dropdown-v1__item" onClick={() => { toggleMoreMenu(0); updateFilter('engine', Engine) }}>
                            {Engine} ({filterItems.Engine[Engine]})
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>

                <li className="dropdown-v1 srp-dropdown__engine" onMouseLeave={hideMenus} onClick={() => toggleMoreMenu(1)}>
                  <h2 className="dropdown-v2__header">City MPG</h2>
                  {isMoreOpen[1] &&
                  (<ul className="sub-dropdown-v2 search-dropdown">
                      <li className="sub-dropdown-v1__item" onClick={() => { updateFilter(null, null) }}>Any City MPG</li>
                      {Object.keys(filterItems.CityMPG).sort().filter((r) => filterItems.CityMPG[r] > 0).map(CityMPG => {
                        return (
                          <li className="sub-dropdown-v1__item" onClick={() => { toggleMoreMenu(1); updateFilter('citympg', CityMPG) }}>
                            {CityMPG} ({filterItems.CityMPG[CityMPG]})
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>

                <li className="dropdown-v1 srp-dropdown__engine" onMouseLeave={hideMenus} onClick={() => toggleMoreMenu(2)}>
                  <h2 className="dropdown-v2__header">Highway MPG</h2>
                  {isMoreOpen[2] &&
                  (<ul className="sub-dropdown-v2 search-dropdown">
                      <li className="sub-dropdown-v1__item" onClick={() => { updateFilter(null, null) }}>Any Highway MPG</li>
                      {Object.keys(filterItems.HwyMPG).sort().filter((r) => filterItems.HwyMPG[r] > 0).map(HwyMPG => {
                        return (
                          <li className="sub-dropdown-v1__item" onClick={() => { toggleMoreMenu(2); updateFilter('hwympg', HwyMPG) }}>
                            {HwyMPG} ({filterItems.HwyMPG[HwyMPG]})
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              </ul>
            </div>

          </ul>

          <div>
          </div>
        </div>*/}
        <div className="content-wrap">
          <div className="sidebar">
            {/*<form className="sidebar-btns sidebar-btns-reset">
              <button className="srp-search__reset-btn">Reset Filters</button>
            </form>*/}
            <div className="sidebar__section-01">
              <div className="switch-btns">

                <button className={ IsNew() ? 'switch-btns__btn condition-new-switch-btns__btn switch-btns__btn_active' : 'switch-btns__btn condition-new-switch-btns__btn' } onClick={() => { updateFilter('new','true'); toggleClass("switch-btns__btn","condition-new-switch-btns__btn","switch-btns__btn_active")}}>New</button>
                <button className={ IsUsed() ? "switch-btns__btn condition-used-switch-btns__btn switch-btns__btn_active" : "switch-btns__btn condition-used-switch-btns__btn " } onClick={() => { updateFilter('new','false'); toggleClass("switch-btns__btn","condition-used-switch-btns__btn","switch-btns__btn_active")}}>Used</button>
              </div>
              <div className="options-list-v2 mileage enabled">
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.mileage.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Mileage: <span className="options-list-v2__selected-value" >{getFilterLabel('mileage', '0')}</span></span>
                  <span className="options-list-v2__header-arrow" ></span>
                </button>
                <div className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                  <Slider
                    value={sliderMileageValue}
                    onChange={mileageChange}
                    // valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    steps={MileageRange}
                    min={0}
                    max={store.mileage[store.mileage.length - 1]}
                  />
                </div>
              </div>
              <div className="options-list-v2 year enabled">
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.year.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Year: <span className="options-list-v2__selected-value" >{getFilterLabel('year', 'Year') == "Year" ?  store.year[0] + " - " + store.year[store.year.length - 1] : getFilterLabel('year', 'Year') }</span></span>
                  <span className="options-list-v2__header-arrow" ></span>
                </button>
                <div className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                  <Slider
                    value={sliderYearValue}
                    onChange={yearChange}
                    // valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    steps={1}
                    min={store.year[0]}
                    max={store.year[store.year.length - 1]}
                  />
                </div>
              </div>
              {store.price[store.price.length - 1] !== undefined ?
                  <div className="options-list-v2 price enabled">
                    <button className="options-list-v2__header options-list-v2__header_color"
                            onClick={() => {
                              document.querySelector('.price.options-list-v2').classList.toggle('options-list-v2_visible')
                            }}>
                      <span>Price: <span
                          className="options-list-v2__selected-value">{getFilterLabel('cost', '0') === "Price" ? "$" + "0.00 - $" + numberWithCommas(store.price[store.price.length - 1]) + ".00+" : getFilterLabel('cost', '0')}</span></span>
                      <span className="options-list-v2__header-arrow"></span>
                    </button>
                    <div
                        className="range-slider-v2 options-list-v2__item-container options-list-v2__item-container_flex">
                      <Slider
                          value={sliderCostValue}
                          onChange={costChange}
                          // valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          steps={CostRange}
                          min={0}
                          max={store.price[store.price.length - 1]}
                      />
                    </div>
                  </div>
              :<></>
              }
              {/* <div className={"options-list-v2 dealer "+ (getFilterItems("DealerName").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.dealer.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Location: <span className="options-list-v2__selected-value">{getFilterLabel('dealer','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("DealerName").map(Dealer => {
                    let toggleClassName = "dealer-" + Dealer.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.DealerName[Dealer].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('dealer', Dealer.toString().split(" ").join(""),Dealer); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Dealer} {filterItems.DealerName[Dealer].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div> */}
              <div className={"options-list-v2 make "+ (getFilterItems("Make").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.make.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Make: <span className="options-list-v2__selected-value">{getFilterLabel('make','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Make").map(Make => {
                    let toggleClassName = "make-" + Make.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.Make[Make].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('make', Make.toString().split(" ").join("").split("-").join(""),Make); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Make} {filterItems.Make[Make].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 model "+ (getFilterItems("Model").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.model.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Model: <span className="options-list-v2__selected-value">{getFilterLabel('model','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Model").map(Model => {
                    let toggleClassName = "model-" + Model.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.Model[Model].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('model', Model.toString().split(" ").join("").split("-").join(""),Model); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Model} {filterItems.Model[Model].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 trim "+ (getFilterItems("Trim").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.trim.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Trim: <span className="options-list-v2__selected-value">{getFilterLabel('trim','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Trim").map(Trim => {
                    let toggleClassName = "trim-" + Trim.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "options-list-v2__item"
                    return (
                      <label className={mainClassName + " " + toggleClassName + (filterItems.Trim[Trim].selected ? " " + checkedClassName : "")} >
                        <input type="checkbox"  className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('trim', Trim.toString().split(" ").join("").split("-").join(""),Trim); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Trim} {filterItems.Trim[Trim].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 bodystyle "+ (getFilterItems("BodyStyle").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.bodystyle.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Body Style: <span className="options-list-v2__selected-value">{getFilterLabel('bodystyle','')}</span></span>
                  <span className="options-list-v2__header-arrow" ></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("BodyStyle").map(BodyStyle => {
                    let toggleClassName = "bodystyle_" + BodyStyle.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "bodystyle.options-list-v2__item"
                    return (
                      <label className={"bodystyle options-list-v2__item options-list-v2__item_bodystyle " + toggleClassName + (filterItems.BodyStyle[BodyStyle].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('bodystyle', BodyStyle.toString().split(" ").join("").split("-").join(""),BodyStyle); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{BodyStyle} {filterItems.BodyStyle[BodyStyle].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 exterior "+ (getFilterItems("GenericExteriorColor").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.exterior.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Exterior Color: <span className="options-list-v2__selected-value">{getFilterLabel('exteriorcolor','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>

                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("GenericExteriorColor").map(GenericExteriorColor => {
                    let toggleClassName = "color_" + GenericExteriorColor.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "exterior.options-list-v2__item"
                    return (
                      <label className={"exterior options-list-v2__item options-list-v2__item_color " + toggleClassName + (filterItems.GenericExteriorColor[GenericExteriorColor].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('exteriorcolor', GenericExteriorColor,GenericExteriorColor); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{GenericExteriorColor} {filterItems.GenericExteriorColor[GenericExteriorColor].count} </span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 interior "+ (getFilterItems("InteriorColor").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.interior.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Interior Color: <span className="options-list-v2__selected-value">{getFilterLabel('interiorcolor','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("InteriorColor").map(InteriorColor => {
                    let toggleClassName = "color_" + InteriorColor.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-').split('/').join('-').toLowerCase()
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "interior.options-list-v2__item"
                    return (
                      <label className={"interior options-list-v2__item options-list-v2__item_color " + toggleClassName + (filterItems.InteriorColor[InteriorColor].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('interiorcolor', InteriorColor,InteriorColor); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{InteriorColor} {filterItems.InteriorColor[InteriorColor].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
              <div className={"options-list-v2 drivetrain "+ (getFilterItems("Drivetrain").length === 0 ?"disable": "enabled") }>
                 <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.drivetrain.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Drive Type: <span className="options-list-v2__selected-value">{getFilterLabel('drivetrain','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Drivetrain").map(Drivetrain => {
                    let toggleClassName = "drivetrain-" + Drivetrain.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "drivetrain.options-list-v2__item"
                    return (
                      <label className={"drivetrain options-list-v2__item " + toggleClassName + (filterItems.Drivetrain[Drivetrain].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('drivetrain', Drivetrain.toString().split(" ").join("").split("-").join(""),Drivetrain); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Drivetrain} {filterItems.Drivetrain[Drivetrain].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>

              <div className={"options-list-v2 transmission "+ (getFilterItems("Transmission").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.transmission.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Transmission: <span className="options-list-v2__selected-value">{getFilterLabel('transmission','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Transmission").map(Transmission => {
                    let toggleClassName = "transmission-" + Transmission.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "transmission.options-list-v2__item"
                    return (
                      <label className={"transmission options-list-v2__item " + toggleClassName + (filterItems.Transmission[Transmission].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('transmission', Transmission.toString().split(" ").join("").split("-").join(""),Transmission); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Transmission} {filterItems.Transmission[Transmission].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>


              <div  style={{ display: "none" }} className={"options-list-v2 engine engine-content "+ (getFilterItems("Engine").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.engine.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Engine: <span className="options-list-v2__selected-value">{getFilterLabel('engine','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("Engine").map(Engine => {
                    let toggleClassName = "engine-" + Engine.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "engine.options-list-v2__item"
                    return (
                      <label className={"engine options-list-v2__item " + toggleClassName + (filterItems.Engine[Engine].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('engine', Engine.toString().split(" ").join("").split("-").join(""),Engine); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{Engine} {filterItems.Engine[Engine].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>

              <div className={"options-list-v2 fuel "+ (getFilterItems("EngineFuelType").length === 0 ?"disable": "enabled") }>
                <button className="options-list-v2__header options-list-v2__header_color"
                        onClick={() => {document.querySelector('.fuel.options-list-v2').classList.toggle('options-list-v2_visible')}}>
                  <span>Fuel: <span className="options-list-v2__selected-value">{getFilterLabel('fuel','')}</span></span>
                  <span className="options-list-v2__header-arrow"></span>
                </button>
                <div className="options-list-v2__item-container options-list-v2__item-container_flex">
                  {getFilterItems("EngineFuelType").map(EngineFuelType => {
                    let toggleClassName = "fuel-" + EngineFuelType.replace(/[^a-zA-Z0-9]/g, '').split(' ').join('-')
                    let checkedClassName = "options-list-v2__item_checked"
                    let mainClassName = "fuel.options-list-v2__item"
                    return (
                      <label className={"fuel options-list-v2__item " + toggleClassName + (filterItems.EngineFuelType[EngineFuelType].selected ? " " + checkedClassName : "")}>
                        <input type="checkbox" className="options-list-v2__checkbox"
                               onClick={() => {updateFilter('fuel', EngineFuelType.toString().split(" ").join("").split("-").join(""),EngineFuelType); toggleClass(mainClassName,toggleClassName,checkedClassName) }} />
                        <span className="options-list-v2__text">{EngineFuelType} {filterItems.EngineFuelType[EngineFuelType].count}</span>
                      </label>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="sidebar-mobile-btns-reset">
              <Link to={(typeof window !== 'undefined' ? window.location.href : "/")} className="switch-btns__btn srp-search__clear-results" >Clear</Link>
              <button className="switch-btns__btn srp-search__see-results"
                      onClick={() => {window.scrollTo(0,0);document.querySelector('.srp-page-wrap').classList.toggle('open-filters-srp-page-wrap_active');  document.querySelector('.open-filters-btn').classList.toggle('open-filters-btn__text_active');  document.querySelector('.content-wrap').classList.toggle('filters-wrap_visible')}}>
                See {StateResults.length} Results </button>
            </div>
          </div>

          <div className="sr-wrap">
            <div className="srp-row srp-search">
              <form id="srp-search-form" className="srp-search__search-form">
              <label hidden htmlFor="srp-search-form">Search</label>
                {/* <input  className="srp-search__search-input" type="search" onChange={text => updateFilter("search", text.target.value)} placeholder="Type Here" /> */}
                <input className="srp-search__search-input"  style={{fontSize: "16px"}} type="search" onChange={function (text){
                         updateFilter("search", text.target.value)
                        //  if(text.target.value == ''){
                        //    removeUrlParameter("search")
                        //  }
                       }}
                       onKeyPress={function(e) {
                        var KeyID = e.which;
                        switch(KeyID)
                        {
                          case 13:
                            e.preventDefault();
                            if(e.target.value.trim() == ''){
                              updateFilter("search", e.target.value.trim())
                              // removeUrlParameter("search")
                              break;
                            }
                            if(!SearchValueCheck(e.target.value)){
                              updateFilter("search", e.target.value)
                            }
                            break;
                        }
                      }}
                       onKeyDown={function(e) {
                         var KeyID = e.which;
                         switch(KeyID)
                         {
                           case 8:
                             if(e.target.value.trim() == ''){
                               updateFilter("search", e.target.value.trim())
                              //  removeUrlParameter("search")
                               break;
                             }
                             if(!SearchValueCheck(e.target.value)){
                               updateFilter("search", e.target.value)
                             }
                             break;
                         }
                       }} placeholder={'Type Here'}
                         defaultValue={
                           (StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"}).length > 0) ?
                               StateFilters.filter(con => { return con.value !== null && con.value !== '' && con.name == "search"})[0].value :
                               ''}  />
                <button className="srp-search__reset-btn">Reset Filters</button>
              </form>
            </div>
            <div className="srp-row srp-row-top">
              <h1 className="inventory-type-title">
                {getHeaderLabel()} Inventory
              </h1>
            </div>
            <div className="srp-row">
              <p className="num-of-vehicles">
                <span id="results" className="num-of-vehicles__number">{StateResults.length}</span> vehicles found
              </p>

              { !additionalInventory && <a href="?fav=true" aria-label="Favorites" className="favorites-link" style={
                {color: "var(--secondary-accent)", fontFamily: "var(--primary-font)"}
              }>
                Favorites (<span className="favorites-link__num">{StateFavorites.length}</span>)
              </a>}
              { additionalInventory && soldSort ? <></> : <ul className="dropdown-sorting"  onMouseLeave={hideMenus}  onClick={() => toggleMenu(6)}>
                  <li className="dropdown-v1 srp-dropdown__model" onMouseLeave={hideMenus}  onClick={() => toggleMenu(6)}>
                    <div className="sr-dropdown__inner">
                      <p className="sr-dropdown__name">{getStorageItem("sorting:Title") ?? "Sort By"}</p>
                    </div>
                    {isOpen[6] && (<ul className="sub-dropdown-v1 search-dropdown">
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Price", "Lowest", "Price - Lowest First"); }}>Price - Lowest First</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Price", "Highest", "Price - Highest First"); }}>Price - Highest First</li>
                      
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Year", "Oldest", "Year - Oldest First"); }}>Year - Oldest First</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Year", "Newest", "Year - Newest First"); }}>Year - Newest First</li>

                      {/* <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Mileage", "Lowest", "Mileage - Lowest First"); }}>Mileage - Lowest First</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Mileage", "Highest", "Mileage - Highest First"); }}>Mileage - Highest First</li>

                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Stock", "Out", "In Stock - Out Of Stock First"); }}>In Stock - Out Of Stock First</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Stock", "In", "In Stock - In Stock First"); }}>In Stock - In Stock First</li>

                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Date", "Oldest", "In Stock Date - Oldest First"); }}>In Stock Date - Oldest First</li>
                      <li className="sub-dropdown-v1__item" onClick={() => { toggleMenu(6); setSortingItems("Date", "Newest", "In Stock Date - Newest First"); }}>In Stock Date - Newest First</li> */}
                    </ul>)}
                  </li>
              </ul>}
            </div>
            <div className={StateResults.length === 0 ? "num-of-vehicles sr__no_results" :"sr__has_results" }> Our inventory is changing constantly. Check back soon.</div>
            <ModalVehicleProvider>
              <SearchRecommended props={{filterState, additionalInventory,
                showSpecs, showTrim, fallbackText, siteSettings,StateResults, setFavorites, theSlug,
                SRPCardBtns, msrpClass, formatPrice, allDataDict, unavailableImg,
                lead_url, site_id, gtmId, sortTheItems, disclaimerCalculator, disclaimerContact,
                disclaimerFinanceApp, defaultMonth, downpayment, rates, AutoCheckEnabled, HideNewUsed,
                overwriteCTAs, overwriteInlineOffers, staticBtns, staticSrpInlineOffers, netPriceCustomText
              }}/>
            </ModalVehicleProvider>
          </div>
        </div>
        {pageContext?.pageContent?.SRP?.disableBottomHtml?
          null
          :
          <div dangerouslySetInnerHTML={{ __html: botHtmlSRP }} />
        }
        <div dangerouslySetInnerHTML={{ __html: staticBottomHtmlSRP }} />
        <div className="sr-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }} />
        <data id="page" value="Search Results Page"></data>
      </div>
    </Layout >
  )
}
export default SearchPage

export const pageQuery = graphql`
query (
  $DealerID: String
) {
  site {
    siteMetadata {
      site_id
      lead_url
    }
  }
  AltLunrIndex(dealerId: $DealerID)
  siteData {
   siteData {
      site_id
      siteSettings
      siteMetadata {
        currentTemplateID
        siteName
      }
    }
  }
  AllInventory(dealerId: $DealerID) {
    DealerID
    VIN
    Pricing {
      Cost
      ExtraPrice1
      ExtraPrice2
      ExtraPrice3
      List
      Special
    }
    SoldDate
    VehicleInfo {
      IsNew
      Model
      Make
      Year
      VIN
      BodyType
      VideoUrl
      StockNumber
      Comments
      Comments2
      Comments3
      InteriorMaterial
      DealerName
      Trim
      VehicleStatus
      Mileage
      CityMPG
      HwyMPG
      Drivetrain 
      EngineFuelType
      InteriorColor
      GenericExteriorColor
      Engine
      BodyStyle
      Transmission
      PhotoCount 
    }
    ListOfOptions {
      Description
      Type
    }
    ListOfPhotos{
      PhotoUrl
      VehiclePhotoID
      Order
    }
  }
}`
